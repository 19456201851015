// extracted by mini-css-extract-plugin
export var TenutaSeperatorSection = "Tenuta-module--TenutaSeperatorSection--a9caf";
export var bannerImg = "Tenuta-module--bannerImg--29fa5";
export var border = "Tenuta-module--border--b56b4";
export var highlightText = "Tenuta-module--highlightText--7d7cd";
export var imageBox = "Tenuta-module--imageBox--b6dc7";
export var imageBox_2 = "Tenuta-module--imageBox_2--e90a2";
export var imgTitle = "Tenuta-module--imgTitle--953ec";
export var lastImage = "Tenuta-module--lastImage--84c23";
export var numberContent = "Tenuta-module--numberContent--6c058";
export var numberLeft = "Tenuta-module--numberLeft--16301";
export var numberRight = "Tenuta-module--numberRight--c34db";
export var numberThird = "Tenuta-module--numberThird--b329b";
export var tenutaCtaSection = "Tenuta-module--tenutaCtaSection--a4700";
export var tenutaParaFirst = "Tenuta-module--tenutaParaFirst--f850e";
export var territorioSection = "Tenuta-module--territorioSection--4115e";