import React, { useContext } from "react";
import CtaCard from "../../Components/CtaCard/CtaCard";
import SliederImage from "../../Components/SliderImage/SliederImage";
import * as styles from "./Tenuta.module.css";
import Footer from "../../Components/Footer/Footer";
import Header from "../../Components/Header/Header";
import { graphql } from "gatsby";
import { SEO } from "../../Components/Seo/seo";
import { languageContext } from "../../Components/Header/Header";
import { AnimationOnScroll } from 'react-animation-on-scroll';
import 'animate.css';
import classNames from "classnames";
import HeroBanner from "../../Components/HeroBanner/HeroBanner";
import img12 from "../../assets/images/home/tenuta/img12.png";
import img13 from "../../assets/images/home/tenuta/img13.png";
import img14 from "../../assets/images/home/tenuta/img14.png";
import img15 from "../../assets/images/home/tenuta/img15.png";

const Index = ({ data }) => { 
  const llanguage = useContext(languageContext);
  const { language } = llanguage;
  const localizations = data.allStrapiTenuta.edges[0]?.node?.localizations || {};
  

  const {
    ContentWithImage: localContentWithImage,
    HeadingContent: localHeadingContent,
    TenutaCTA: localTenutaCTA,
    TenutaSeperator: localTenutaSeperator,
    TenutaSeperator2: localTenutaSeperator2
  } = localizations?.data[0]?.attributes || {};
  const {
    ContentWithImage,
    HeadingContent,
    Numbers,
    TenutaCTA,
    TenutaSeperator,
    TenutaSeperator2,
    Text,
    description,
    title,
  } =
    language === "it"
      ? localizations?.data[0]?.attributes
      : data?.allStrapiTenuta?.edges[0]?.node || {};


  const handleUrls = (language) => {
    const urls = [];
    if (language == "it") {
      localTenutaSeperator.forEach((item, index) => {
        const url = item.Image.data.attributes.url; 
        urls.push(url);
      });
    } else if(language == "en") {
      TenutaSeperator.forEach((item, index) => {
        const url = item.Image.url;
        urls.push(url);
      });
    }  
    return urls;
  };
  
  // Call the function with the desired language
  const urlsArray = handleUrls(language);

 


  return (
  <>
    <div className="pageWrapper">
      <Header heroBanner={true}/>
       <HeroBanner
       bannerImg={language === "it"
       ? localHeadingContent?.Image?.data?.attributes?.url
       : HeadingContent?.Image?.url}
       bannerDescription={HeadingContent?.Description}
       bannerTitle={HeadingContent?.Title}
       />
      {/* <TenutaBanner
        tenuta={true}
        bannerImg={language === "it"
        ? localHeadingContent?.Image?.data?.attributes?.url
        : HeadingContent?.Image?.url}
        bannerDescription={HeadingContent?.Description}
        bannerTitle={HeadingContent?.Title}
      /> */}

      <div className={styles.tenutaCtaSection}>
        <CtaCard
          tenuta={true}
          ctaDescription={ContentWithImage?.Description}
          ctaImage={language === "it"
          ? localContentWithImage?.Image?.data?.attributes?.url
          : ContentWithImage?.Image?.url}
        />
      </div>

      {/* Short Highlights  */}
      <div className={styles.highlightText}>
      <AnimationOnScroll  animateIn='animate__animated animate__fadeInUp'>
        <h6 className="animate__animated animate__fadeInUp">{Text}</h6>
        </AnimationOnScroll>
      </div>

      {/* <div className={styles.imageBox}>
        <img src={language === "it"
          ? localTenutaSeperator?.Image?.data?.attributes?.url
          : TenutaSeperator?.Image?.url} alt="icon" />
      </div> */}

        <SliederImage
          separatorImg={urlsArray}
        />

      {/* Un Territorio */}
      <div className={styles.territorioSection}>
      <AnimationOnScroll  animateIn='animate__animated animate__fadeInUp'>
        <h2 className="animate__animated animate__fadeInUp">{TenutaCTA?.Title}</h2>
        </AnimationOnScroll>
        <AnimationOnScroll  animateIn='animate__animated animate__fadeInUp'>
        <p className={classNames("animate__animated animate__fadeInUp", styles.tenutaParaFirst)}>{TenutaCTA.Description}</p>
        </AnimationOnScroll>
        <AnimationOnScroll  animateIn='animate__animated animate__fadeInUp'>
        <a href={TenutaCTA?.Icon?.Link?.href} className={classNames(styles.imgTitle, 'animate__animated animate__fadeInUp')}>
          <h5>{TenutaCTA?.Icon?.Link?.label}</h5>
          <img src={language === "it"
          ? localTenutaCTA?.Icon?.Image?.data?.attributes?.url
          : TenutaCTA?.Icon?.Image?.url} alt="icon" />
        </a>
        </AnimationOnScroll>
        <div className={styles.bannerImg}>
          <img src={language === "it"
          ? localTenutaCTA?.CTADetail?.Image?.data?.attributes?.url
          : TenutaCTA?.CTADetail?.Image?.url} alt="icon" />
        </div>
        <AnimationOnScroll  animateIn='animate__animated animate__fadeInUp'>
        <h2 className={classNames( 'animate__animated animate__fadeInUp')}>{title}</h2>
        </AnimationOnScroll>
        <div className={styles.numberContent}>
        <AnimationOnScroll  animateIn='animate__animated animate__fadeInUp'>
          <p className="animate__animated animate__fadeInUp">{description}</p>
          </AnimationOnScroll>
          <div className={styles.numberLeft}>
          <AnimationOnScroll  animateIn='animate__animated animate__fadeInUp'>
            <h1 className="animate__animated animate__fadeInUp">{Numbers[0]?.number}</h1>
            </AnimationOnScroll>
            <AnimationOnScroll  animateIn='animate__animated animate__fadeInUp'>
            <h6 className="animate__animated animate__fadeInUp">{Numbers[0]?.detail}</h6>
            </AnimationOnScroll>
          </div>
          <span className={styles.border}></span>
          <div className={styles.numberRight}>
          <AnimationOnScroll  animateIn='animate__animated animate__fadeInUp'>
            <h1 className="animate__animated animate__fadeInUp">{Numbers[1]?.number}</h1>
            </AnimationOnScroll>
            <AnimationOnScroll  animateIn='animate__animated animate__fadeInUp'>
            <h6 className="animate__animated animate__fadeInUp">{Numbers[1]?.detail}</h6>
            </AnimationOnScroll>
          </div>
        </div>
      </div>

      <div className={styles.imageBox}>
        <img className={styles.lastImage} src={language === "it"
          ? localTenutaSeperator2?.data?.attributes?.url
          : TenutaSeperator2?.url} alt="icon" />
      </div>

    </div>
    <Footer />
  </>
  );
};

export default Index;

export const query = graphql`
  query TenutaQuery {
    allStrapiTenuta {
      edges {
        node {
          ContentWithImage {
            Image {
              url
            }
            Description
          }
          HeadingContent {
            Description
            Image {
              url
            }
            Title
          }
          Numbers {
            number
            detail
          }
          TenutaCTA {
            CTADetail {
              Image {
                url
              }
              MainLink {
                href
                label
              }
            }
            Description
            Icon {
              Image {
                url
              }
              Link {
                href
                label
              }
            }
            Title
          }
          TenutaSeperator {
            Image {
              url
            }
          }
          TenutaSeperator2 {
            url
          }
          Text
          description
          title
          MetaKeyword
          MetaDescription
          localizations {
            data {
              attributes {
                ContentWithImage {
                  Description
                  Image {
                    data {
                      attributes {
                        url
                      }
                    }
                  }
                }
                HeadingContent {
                  Description
                  Title
                  Image {
                    data {
                      attributes {
                        url
                      }
                    }
                  }
                }
                Numbers {
                  detail
                  number
                }
                TenutaCTA {
                  Description
                  Title
                  CTADetail {
                    Image {
                      data {
                        attributes {
                          url
                        }
                      }
                    }
                  }
                  Icon {
                    Image {
                      data {
                        attributes {
                          url
                        }
                      }
                    }
                    Link {
                      href
                      label
                    }
                  }
                }
                TenutaSeperator {
                  Image {
                    data {
                      attributes {
                        url
                      }
                    }
                  }
                }
                TenutaSeperator2 {
                  data {
                    attributes {
                      url
                    }
                  }
                }
                Text
                description
                title
                MetaKeyword
                MetaDescription
              }
            }
          }
        }
      }
    }
  }
`;

export const Head = ({ data }) => {
  const localizations =
    data?.allStrapiTenuta?.edges[0]?.node?.localizations || {};
  const { MetaKeyword , MetaDescription } = localizations?.data[0]?.attributes
  return (
    <SEO
      title={MetaKeyword} description={MetaDescription}
    />
  );
};
