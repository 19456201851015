import React from 'react';
import classNames from "classnames";
import { AnimationOnScroll } from 'react-animation-on-scroll';
import 'animate.css';
import * as styles from "./HeroBanner.module.css";

const HeroBanner = ({bannerImg, bannerDescription, bannerTitle}) => {
 
    return (
        <div className={classNames(styles.BannerSection)}>
        <div className={styles.bannerContent}>
          <img className={styles.bannerImg} src={bannerImg} alt="" />
        </div>
        <div
          className={`${styles.TextContent} ${styles.TextContent2}}`}
        >
          <AnimationOnScroll  animateIn='animate__animated animate__fadeInUp'>
          <h2 className="animate__animated animate__fadeInUp">{bannerTitle}</h2>
          </AnimationOnScroll>
          <AnimationOnScroll  animateIn='animate__animated animate__fadeInUp'>
          <p className="animate__animated animate__fadeInUp">{bannerDescription}</p>
          </AnimationOnScroll>
        </div>
      </div>
    );
};

export default HeroBanner;