// extracted by mini-css-extract-plugin
export var BannerSection = "HeroBanner-module--BannerSection--6b273";
export var TextContent = "HeroBanner-module--TextContent--a7361";
export var TextContent2 = "HeroBanner-module--TextContent2--4bb40";
export var TextContent3 = "HeroBanner-module--TextContent3--fe359";
export var VisitContent = "HeroBanner-module--VisitContent--b351f";
export var bannerContent = "HeroBanner-module--bannerContent--80141";
export var bannerImg = "HeroBanner-module--bannerImg--1e947";
export var displayNone = "HeroBanner-module--displayNone--e3d47";
export var pressSection = "HeroBanner-module--pressSection--e15c8";
export var tenutaParra = "HeroBanner-module--tenutaParra--2f4cb";
export var viniSection = "HeroBanner-module--viniSection--4d6f4";